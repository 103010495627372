// app/javascript/controllers/barcode_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['entryTagsTable', 'entryTagsTableBody', 'input'];

  connect() {
    this.tags = new Set();

    // Init num of tags in 0
    this.numTags = 0;
    $('#entries-tag-counter').text(this.numTags);

    this.isTableVisible();

    $('.modal').on('hidden.bs.modal', () => {
      this.resetModal();
    });
  }

  preventSubmit(event) {
    if (event.key === "Enter") { event.preventDefault(); }
  }

  processBarcode(event) {
    if (event.key === "Enter") {
      const barcode = this.inputTarget.value.trim();
      this.sendBarcodeToScan(barcode);
      this.inputTarget.value = '';
    }
  }

  sendBarcodeToScan(barcode){
    fetch("/drying_kiln_entries/scan_code", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify( { tag_input: barcode } )
    })
    .then(response => response.json())
    .then(data => {
      if (data.valid) {

        if(this.tags.has(data.lot_tag_id)){
          swal('Código no válido', 'Esta etiqueta ya ha sido añadida', 'error');
          return;
        }
        this.tags.add(data.lot_tag_id);
        console.log(this.tags);
        this.appendTag(data.lot_tag_id, data.lot_number, data.tag, data.drying_time);

      } else {
        swal('Código no válido', data.message, 'error');
      }
    })
    .catch(error => {
      alert("Error al enviar el código de barras:", error);
    });
  }

  appendTag(lot_tag_id, lot_number, tag, drying_time) {
    const newRow = document.createElement('tr');

    for (let i = 0; i < 4; i++) {
      const cell = document.createElement('td');
      cell.style.width = '30%';
      cell.classList.add('text-center');

      switch (i) {
      case 0:
        cell.textContent = lot_number;
        break;
      case 1:
        cell.textContent = tag;
        break;
      case 2:
        cell.textContent = drying_time;
        break;
      default:
        const del_btn = document.createElement('button');
        del_btn.textContent = 'Remover';
        del_btn.classList.add('btn', 'btn-danger');

        del_btn.addEventListener('click', () => {
          this.tags.delete(lot_tag_id);
          $('#tags_raw_input').val(
            $('#tags_raw_input').val()
                                .replace(new RegExp(`\\b${lot_tag_id}\\b`, 'g'), "")
                                .replace(/\s+/g, ' ')
                                .trim()
          );
          console.log(this.tags);

          // del table row
          newRow.remove();
          this.updateCounter();
          this.isTableVisible();
        });

        cell.appendChild(del_btn);
        break;
      }

      newRow.appendChild(cell);
    }

    this.entryTagsTableBodyTarget.appendChild(newRow);

    $('#tags_raw_input').val(Array.from(this.tags).join(' '));

    this.updateCounter();
    this.isTableVisible();
  }

  updateCounter() {
    this.numTags = this.entryTagsTableBodyTarget.querySelectorAll('tr').length;
    $('#entries-tag-counter').text(this.numTags);
  }

  isTableVisible(){
    if(this.numTags === 0){
      this.entryTagsTableTarget.style.display = 'none';
      return;
    }
    this.entryTagsTableTarget.style.display = 'block';
  }

  resetModal(){
  // Clean the form fields
    // Bootstrap std reset: best case scenario only!
    $('.modal').find('form').trigger('reset');

    // If above didn't work, then proceed to manual reset.

    // Remove simple form gem red warnings.
    $('.has-error').removeClass();
    $('.help-block').remove();

    // 1st: drying_kiln_id input
    this.resetField('#drying_kiln_id_input', 'Selecciona un horno');
    // 2nd: drying_type input
    this.resetField('#drying_type_input', 'Selecciona un tipo de secado');
    // 3rd: drying_cart input
    this.resetField('#drying_cart_input', 'Selecciona un carro');
    $('#drying_cart_field').hide();
    // 4th: tag input
    this.resetField('#tag_input', 'Barcode');

    // Destroy table
    this.entryTagsTableBodyTarget.innerHTML = '';
    this.updateCounter();
    this.isTableVisible();
  }

  resetField(selectId, promptText){
    const $select = $(selectId);
    if (!$select.find('option[value=""]').length) {
      $select.prepend(`<option value="" selected>${promptText}</option>`);
    }
    $select.val('').trigger('change');
  }
}
