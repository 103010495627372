import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['time', 'delayed']
  static values = {
    end: String
  }

  connect() {
    this.update()
    this.timer = setInterval(() => this.update(), 1000)
  }

  disconnect() {
    clearInterval(this.timer)
  }

  update() {
    const endDate = new Date(this.endValue)
    const now = new Date()
    const diff = endDate - now
    if (diff <= 0) {
      this.delayedDrying();
      this.timer = setInterval(() => this.delayedDrying(), 1000);
      return;
    }
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diff % (1000 * 60)) / 1000)
    this.timeTarget.textContent = this.formatTime(days, hours, minutes, seconds)
  }

  delayedDrying() {
    const endDate = new Date(this.endValue)
    const now = new Date()
    const delayedTime = now - endDate;

    const days = Math.floor(delayedTime / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delayedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((delayedTime % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((delayedTime % (1000 * 60)) / 1000)

    this.delayedTarget.textContent = this.formatTime(days, hours, minutes, seconds)
  }

  formatTime(days, hours, minutes, seconds) {
    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`
    } else if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`
    } else {
      return `${seconds}s`
    }
  }
}