import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'exitTagsTable', 'exitTagsTableBody', 'boolValue'];

  connect(){
    this.tags = new Set();

    this.numTags = 0;
    $('#exits-tag-counter').text(this.numTags);

    this.isTableVisible();

    // $('.modal').on('hidden.bs.modal', () => {
    //   this.resetModal();
    // });
  }

  preventSubmit(event) {
    if (event.key === "Enter") { event.preventDefault(); }
  }

  processBarcode(event) {
    if (event.key === "Enter") {
      const barcode = this.inputTarget.value.trim();
      this.sendBarcodeToScan(barcode);
      this.inputTarget.value = '';
    }
  }

  sendBarcodeToScan(barcode, bool_val){
    fetch('/drying_kiln_exits/scan_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify( { tag_input: barcode } )
    })
    .then(response => response.json())
    .then(data =>{
      if (data.valid) {

        if(this.tags.has(data.lot_tag_id)){
          swal('Código no válido', 'Esta etiqueta ya ha sido añadida', 'error');
          return;
        }

        if(data.status !== 'ready'){
          if(this.boolValueTarget.textContent.trim() !== 'true'){
            swal('Código inválido', 'El secado aún no está listo', 'error');
            return;
          }
        }

        this.tags.add(data.lot_tag_id);
        console.log(data);

      this.appendTag(data.lot_tag_id, data.lot_number, data.tag, data.drying_time, data.status);

      } else {
        swal('Código no válido', data.message, 'error');
      }
    })
    .catch(error => {
      console.log("Error al enviar el código de barras:", error);
    });
  }

  appendTag(lot_tag_id, lot_number, tag, time, is_ready){
    const newRow = document.createElement('tr');

    for (let i = 0; i < 5; i++) {
      const cell = document.createElement('td');
      cell.style.width = '30%';
      cell.classList.add('text-center');

      switch (i) {
      case 0:
        cell.textContent = lot_number;
        break;
      case 1:
        cell.textContent = tag;
        break;
      case 2:
        cell.textContent = time;
        break;
      case 3:
        cell.textContent = is_ready;
        break;
      default:
        const del_btn = document.createElement('button');
        del_btn.textContent = 'Remover';
        del_btn.classList.add('btn', 'btn-danger');

        del_btn.addEventListener('click', (event) => {
          event.preventDefault();
          this.tags.delete(lot_tag_id);
          $('#exit_tags_raw_input').val(
            $('#exit_tags_raw_input').val()
                                .replace(new RegExp(`\\b${lot_tag_id}\\b`, 'g'), "")
                                .replace(/\s+/g, ' ')
                                .trim()
          );
          console.log(this.tags);

          // del table row
          newRow.remove();
          this.updateCounter();
          this.isTableVisible();
        });

        cell.appendChild(del_btn);
        break;
      }

      newRow.appendChild(cell);
    }

    this.exitTagsTableBodyTarget.appendChild(newRow);

    $('#exit_tags_raw_input').val(Array.from(this.tags).join(' '));

    this.updateCounter();
    this.isTableVisible();
  }

  updateCounter() {
    this.numTags = this.exitTagsTableBodyTarget.querySelectorAll('tr').length;
    $('#exits-tag-counter').text(this.numTags);
  }

  isTableVisible(){
    if(this.numTags === 0){
      this.exitTagsTableTarget.style.display = 'none';
      return;
    }
    this.exitTagsTableTarget.style.display = 'block';
  }
};